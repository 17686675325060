import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card card-custom mb-5 mb-xxl-8 overflow-hidden" }
const _hoisted_2 = { class: "py-18 px-12" }
const _hoisted_3 = { class: "fs-2x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["card-body p-0 d-flex card-rounded", `bg-light-${_ctx.color}`])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/product",
            class: "text-dark text-hover-primary fw-bolder"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Nike Sneakers")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["fs-3", `text-${_ctx.color}`])
        }, " Get Amazing Nike Sneakers ", 2)
      ]),
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-right bgi-size-cover",
            style: _normalizeStyle([{"transform":"translateX(10%) rotate(-26deg)"}, { backgroundImage: 'url(' + _ctx.image + ')' }])
          }, null, 4))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}