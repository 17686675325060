
import { defineComponent } from "vue";

export default defineComponent({
  name: "Widget2",
  props: {
    color: String,
    image: String,
  },
  components: {},
});
