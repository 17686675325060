
import {defineComponent, onMounted} from "vue";
import EngageWidget1 from "@/view/content/widgets/engage/HeaderPage.vue";
import EngageWidget2 from "@/view/content/widgets/engage/Widget2.vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "marketplace",
  components: {
    EngageWidget1,
    EngageWidget2,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Marketplace", "Negócios");
    });
  },
});
