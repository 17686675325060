
import { defineComponent, toRef, computed } from "vue";
import { useStore } from "vuex";
// import { Storage } from "aws-amplify";

import {
  config,
  displaySidebar,
  displayAside,
  loaderEnabled,
  contentFluid,
  displayScrollTop,
  displayHeader,
  displayToolbar,
} from "@/core/helpers/config";

export default defineComponent({
  name: "header-page",
  props: {
    bgHex: String,
    lg: Boolean,
    isStaticBG: Boolean,
    bgImage: String,
    bgImageSmall: String,
    bgColor: String,
    margin: String,
    title: String,
    subtitle: String,
    description: String,
    description1: String,
    description2: String,
    search: String,
    isSearchable: Boolean,
    breadcrumbs: Object,
  },
  setup(props) {
    const store = useStore();

    const lg = toRef(props, "lg");
    const bgImage = toRef(props, "bgImage");
    const isStaticBG = toRef(props, "isStaticBG");
    // const bgImageSmall = toRef(props, "bgImageSmall");
    const margin = toRef(props, "margin");
    const title = toRef(props, "title");
    const subtitle = toRef(props, "subtitle");
    const description = toRef(props, "description");
    const description1 = toRef(props, "description1");
    const description2 = toRef(props, "description2");
    const search = toRef(props, "search");
    const isSearchable = toRef(props, "isSearchable");
    const breadcrumbs = toRef(props, "breadcrumbs");

    const breadcrumbsCollection = computed(() => {
      return breadcrumbs.value ? breadcrumbs.value : {};
    });

    const padding = computed(() => {
      return lg.value ? "py-10 py-md-14 pl-10" : "py-10 pl-10";
    });

    const mb = computed(() => {
      return lg.value ? "mb-0" : "";
    });

    // const bannerLargeHref = bgImage.value ? bgImage.value : "";
    // const storageBannerLarge = await Storage.get(bannerLargeHref, {
    //   level: "public",
    // });

    // const bannerLarge = computed(() => {
    //   return storageBannerLarge
    //     ? storageBannerLarge
    //     : "media/stock/900x600/3.jpg";
    // });

    // const bannerSmallHref = bgImageSmall.value ? bgImageSmall.value : "";
    // const storageBannerSmall = await Storage.get(bannerSmallHref, {
    //   level: "public",
    // });

    // const bannerSmall = computed(() => {
    //   return storageBannerSmall
    //     ? storageBannerSmall
    //     : "media/stock/900x600/3.jpg";
    // });

    const bgPosition = computed(() => {
      return bgImage.value
        ? "bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
        : "";
    });

    const isImage = computed(() => {
      return isStaticBG.value ? isStaticBG.value : false;
    });

    const width = computed(() => {
      return lg.value ? "w-xxl-600px" : "w-xxl-350px";
    });

    const cardMargin = computed(() => {
      return margin.value ? margin.value : "mb-4";
    });

    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const descriptionText = computed(() => {
      return description.value ? description.value : "";
    });

    const descriptionText1 = computed(() => {
      return description1.value ? description1.value : "";
    });

    const descriptionText2 = computed(() => {
      return description2.value ? description2.value : "";
    });

    const searchText = computed(() => {
      return search.value ? search.value : "";
    });

    const searchCondition = computed(() => {
      return isSearchable.value ? isSearchable.value : false;
    });

    return {
      // bannerLarge,
      // bannerSmall,
      isImage,
      padding,
      mb,
      bgPosition,
      width,
      cardMargin,
      titleText,
      subtitleText,
      descriptionText,
      descriptionText1,
      descriptionText2,
      searchText,
      searchCondition,
      breadcrumbsCollection,
      displaySidebar,
      displayAside,
      loaderEnabled,
      config,
      contentFluid,
      displayScrollTop,
      displayHeader,
      displayToolbar,
      store,
    };
  },
});
